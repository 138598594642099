import React from 'react';

function Projects() {
  return (
    <section id="projects">
      <h2>Projects</h2>
      <ul>
        <li>Project 1: Description of the project.</li>
        <li>Project 2: Description of the project.</li>
        <li>Project 3: Description of the project.</li>
      </ul>
    </section>
  );
}

export default Projects;
