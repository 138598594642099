import React from 'react';

function About() {
  return (
    <section id="about">
      <h2>About Me</h2>
      <p>
        Hi, I'm Akshai, a tech enthusiast and multi-domain developer with a strong focus on Python development.
      </p>
    </section>
  );
}

export default About;
