import React from 'react';

function Skills() {
  return (
    <section id="skills">
      <h2>Skills</h2>
      <ul>
        <li>Python Development</li>
        <li>Web Development</li>
        <li>Machine Learning</li>
        <li>Data Science</li>
        <li>DevOps</li>
      </ul>
    </section>
  );
}

export default Skills;
